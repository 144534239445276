import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from './Main';
import Mint from './Pages/Minting';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Refund from './Pages/Refund';
import Contact from './Pages/Contact';
import Admin from './Pages/admin';


function App(){
    return(
      <Router >
      < >
  
          <Switch >
          <Route exact path = "/" > <Home/> </Route> 
          <Route exact path = "/mint" > <Mint/> </Route>
          <Route exact path = "/terms" > <Terms/> </Route>
          <Route exact path = "/privacy" > <Privacy/> </Route>
          <Route exact path = "/refund" > <Refund/> </Route>
          <Route exact path = "/contact" > <Contact/> </Route>
          <Route exact path = "/admin" > <Admin/> </Route>
          </Switch> 
  
          </>
        </Router>  
    );
}

export default App;