
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCOqyoX5WFvZFFhyRZr0Zqhwi0wEjR6Pwo",
  authDomain: "chubby-primates.firebaseapp.com",
  projectId: "chubby-primates",
  storageBucket: "chubby-primates.appspot.com",
  messagingSenderId: "701228042852",
  appId: "1:701228042852:web:5db4c4726ef34d41b5ed71"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;