import React from 'react';

const Contact = () => {
  return (
    <div>
        <section id="terms-of-service">
          <div className="card1">
            <h1 className="primary-heading">Contact US</h1>
            <p className="paragraph">
                <p>Address: <br/>15695 Water Spring Blvd, Winter Garden 34787 Florida United States</p><br/>
                <p>Phone Number: <br/><a href="tel:+14078815750">+14078815750</a></p><br/>
                <p>Mail Us: <br/><a href="mailto:+14078815750">Contact@chubbyprimates.com</a></p><br/>
            </p>
            <br/><br/>
          </div>
        </section>

    </div>
  );
};

export default Contact;
