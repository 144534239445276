import React from 'react';

const Privacy = () => {
  return (
    <div>
        <section id="terms-of-service">
          <div className="card1">
            <h1 className="primary-heading">Privacy Policy</h1>
            <p className="paragraph">
            At Chubby primates, we take the privacy of our customers very seriously. We understand the importance of protecting your personal information and are committed to ensuring that it is kept safe and secure. The following privacy policy explains how we use and protect your personal information when you purchase a ticket to participate in our raffles for cars, scooters, and phones.
            <br/><br/><br/>
            Collection of Personal Information:<br/>
            When you purchase a ticket on our website, we collect personal information from you, such as your name, email address, phone number, and payment information. This information is necessary for us to process your purchase and notify you if you win. We do not share your personal information with any third parties, except as required to process your purchase or as required by law.<br/><br/>
            Security of Personal Information:<br/>
            We take all reasonable steps to ensure the security of your personal information, including implementing appropriate technical and organizational measures to protect against unauthorized access, disclosure, alteration, or destruction.<br/><br/>
            Use of Cookies:<br/>
            We use cookies to enhance your user experience and to collect information about how you use our website. Cookies are small text files that are stored on your device when you visit our website. You can change your browser settings to refuse cookies, but this may affect your user experience on our website.<br/><br/>
            Marketing Communications:<br/>
            If you have provided us with your contact information, we may send you marketing communications via email, SMS, or postal mail. You may opt-out of receiving these communications at any time.<br/><br/>
            Third-Party Websites:<br/>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We recommend that you read the privacy policies of these websites before providing any personal information.<br/><br/>
            Changes to Privacy Policy:<br/>
            We reserve the right to update or modify this privacy policy at any time. Any changes will be effective immediately upon posting on our website.
            Please feel free to contact us if you have any questions or concerns about our privacy policy.
            <br/><br/><br/>
            </p>
          </div>
        </section>

    </div>
  );
};

export default Privacy;
