import React, { useState, useEffect } from "react" ;
import db from "../config/firebase.config";
import * as emailjs from "emailjs-com";


const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const Ticket = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [ethWallet, setEthWallet] = useState('');
    const [ticktetnumber, setticktetnumber] = useState('');
    const [phNumber, setphNumber] = useState('');
    const [selName, setselName] = useState('');
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [message, setMessage] = useState('');
    const [numTicketsSold, setNumTicketsSold] = useState(0);

  
    const toggleForm = () => {
    setName('');
    setEmail('');
    setEthWallet('');
    setselName('');
    setphNumber('');
    setticktetnumber('');
    };

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  }

  const handleEthWalletChange = (e) => {
    setEthWallet(e.target.value.toLowerCase());
  }

  const handlephNumberChange = (e) => {
    setphNumber(e.target.value);
  }

  const handleselNameChange = (e) => {
    setselName(e.target.value.toLowerCase());
  }

  

  const handleticktetnumberChange = (e) => {
    setticktetnumber(e.target.value);
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !phNumber || !ticktetnumber) {
      alert('Please fill all the details');
      return;
    }
  
    try {

        // Check if Ticket Number already exists in the database
      const ticktetnumberQuerySnapshot = await db
      .collection('TicketUsers')
      .where('Ticket Number', '==', ticktetnumber)
      .get();
  

      if (!ticktetnumberQuerySnapshot.empty) {
        alert('This Ticket is already booked!');
        return;
      }
  
      await db.collection('TicketUsers').add({
        Name: name,
        Email: email,
        'Ethereum Wallet': ethWallet,
        'Phone Number' : phNumber,
        'Seller Number' : selName,
        'Ticket Number': ticktetnumber,
        Payment: 'Cash',
        
      });
  

      handleMail();
      alert('Data saved successfully!');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  
    setName('');
    setEmail('');
    setEthWallet('');
    setselName('');
    setphNumber('');
    setticktetnumber('');
  };



  const handleLogin = async (event) => {
    event.preventDefault();
    const ownerUsername = event.target.elements.ownerUsername.value;
    const ownerPassword = event.target.elements.ownerPassword.value;
    const credentials = await db.collection('owner').doc('loginCredentials').get();
    const data = credentials.data();
    if (data.username === ownerUsername && data.password === ownerPassword) {
      // If username and password match, hide login form and show user details form
      setShowUserDetails(true);
    } else {
      alert("Incorrect username or password");
    }
  }

  const handleCheckAvailability = async () => {

    const ticktetnumberQuerySnapshot = await db
      .collection('TicketUsers')
      .where('Ticket Number', '==', ticktetnumber)
      .get();
      if (!ticktetnumberQuerySnapshot.empty) {
        setMessage(`Ticket number ${ticktetnumber} is already taken.`);
      }else {
      setMessage(`Ticket number ${ticktetnumber} is available.`);
    }

  };

  const getNumTicketsSold = async () => {
    try {
      const snapshot = await db.collection('TicketUsers').get();
      const numSold = snapshot.size;
      setNumTicketsSold(numSold);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };


  const handleMail = () => {
    var data = {
      to_email:email,
      ticket_num: ticktetnumber,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then(
      function (response) {
        console.log(response.status, response.text);
      },
      function (err) {
        console.log(err);
      }
    );
  }

  const [sellerCounts, setSellerCounts] = useState([]);

  useEffect(() => {
    const getSellerCounts = async () => {
      const sellerCounts = {};
      const snapshot = await db.collection("TicketUsers").get();
      snapshot.forEach((doc) => {
        const sellerName = doc.data()["Seller Number"];
        sellerCounts[sellerName] = (sellerCounts[sellerName] || 0) + 1;
      });
      setSellerCounts(sellerCounts);
    };
    getNumTicketsSold();
    getSellerCounts();
  }, []);
  

  return (
    <>
     <div>
        <section id="terms-of-service">
          <div className="card1">
          {showUserDetails ? (
                <div>
                  <br/>
                  <div className="caption">
                    <label >{numTicketsSold} Tickets Sold</label>
                  </div>
                    <h1 className="primary-heading">User Details</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="caption">
                            <input type="text" placeholder="Name" id="name" value={name} onChange={handleNameChange} />
                            <input type="email" placeholder="Email" id="email" value={email} onChange={handleEmailChange} />
                            <input type="text" placeholder="Metamask Wallet (Optional)" id="ethWallet" value={ethWallet} onChange={handleEthWalletChange} />
                            <input type="tel" placeholder="Phone Number" id="phNumber" value={phNumber} onChange={handlephNumberChange} />
                            <input type="text" placeholder="Seller Name (Optional)" id="selName" value={selName} onChange={handleselNameChange} />
                            <input type="number" placeholder="Ticket Number" id="ticktetnumber" value={ticktetnumber} onChange={handleticktetnumberChange} max={1000} min={1}/>
                            <button type="submit" > Submit </button>
                        </div>
                    </form>
                    <button onClick={toggleForm}> Reset Field </button>

                    <div>
                    <h1 className="primary-heading"> Check Ticket Availability</h1>
                    <div className="caption">
                    <label htmlFor="ticketNumber">Ticket Number: {ticktetnumber}</label>
                    <input type="number" placeholder="Ticket Number" id="ticketNumber" value={ticktetnumber} onChange={handleticktetnumberChange} />
                    <button onClick={handleCheckAvailability}>Check</button>
                    {message && <p>{message}</p>}
                    </div>
                    <h1 className="primary-heading"> Sellers</h1>
                    {Object.entries(sellerCounts).map(([sellerName, count]) => (
                      <div className="caption" key={sellerName}>
                       <label > {sellerName}: {count}</label>
                      </div>
                    ))}

                    </div>
                </div>
                ) : (
                <div>
                <h1 className="primary-heading">Login Admin</h1>
                <form onSubmit={handleLogin}>
                    <div className="caption">
                    <input type="text" placeholder="Username" id="ownerUsername" required />
                    <input type="password" placeholder="Password" id="ownerPassword" required />
                    <button type="submit">Login</button>
                    </div>
                </form>
                </div>
                )}


        <br/><br/><br/>
        </div>
        </section>

    </div>
    </>

  );
};

export default Ticket;
