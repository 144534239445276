import React from 'react';

const Terms = () => {
  return (
    <div>
        <section id="terms-of-service">
          <div className="card1">
            <h1 className="primary-heading">TERMS AND CONDITIONS FOR ONLINE RAFFLE TICKETS</h1>
            <p className="paragraph">
            Thank you for choosing our online raffle ticket platform to participate in the exciting opportunity to win a car, scooter, or iPhone. Please read these terms and conditions carefully before purchasing a ticket. By purchasing a ticket, you agree to be bound by these terms and conditions.
            <br /><br />
            Ticket Purchase: To participate in the raffle, each person must have paid for the tickets in full. The price of each ticket is $125 USD. We will sell a total of 10,000 tickets. Tickets can be purchased through our online platform only.<br />
            Prize Details: The raffle includes 20 cars, 200 scooters, and 10 iPhone 14s. The prizes are subject to availability and may be substituted with a prize of equal or greater value in the event of unavailability.<br />
            Draw Details: The draw will be held in 10 stages, and for every 1000 tickets sold, we will deliver 2 cars, 20 scooters, and 2 iPhones. The draws will take place on the dates announced on our website.<br />
            Refund Policy: You have 72 hours from the time of purchase to request a refund. After 72 hours, you will lose the right to receive a refund. Refunds will not be issued after the draw has taken place.<br />
            Privacy Policy: We take your privacy seriously and will not share your personal information with any third party without your consent. Your personal information will only be used for the purpose of the raffle and will be securely stored.<br />
            Liability: We are not responsible for any losses, damages, or injuries resulting from your participation in the raffle. By purchasing a ticket, you release us from any liability arising from your participation in the raffle.<br />
            Eligibility: Participants must be 18 years or older to purchase a ticket and participate in the raffle. Residents of certain countries may not be eligible to participate due to local laws and regulations.<br />
            Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction where the company is based.<br /><br />
            By purchasing a ticket, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them. Good luck in the raffle!<br /><br /><br />
            </p>
          </div>
        </section>

    </div>
  );
};

export default Terms;
