import React from 'react';

const Refund = () => {
  return (
    <div>
        <section id="terms-of-service">
          <div className="card1">
            <h1 className="primary-heading">Refund Policy</h1>
            <p className="paragraph">
            Refund Policy for Raffle Ticket Sales:
            <br/><br/><br/>
            Thank you for choosing to purchase a raffle ticket from our company. We want you to be completely satisfied with your purchase, and we understand that situations may arise where you may need to request a refund. Please read our refund policy carefully to understand your rights and obligations when requesting a refund.
            <br/><br/><br/>
            Refund Eligibility:<br/>
            You may request a refund of your raffle ticket purchase within 72 hours of the purchase date. After this time, no refunds will be granted. Once a raffle stage has passed and prizes have been awarded, no refunds will be granted for that stage.<br/><br/>
            Refund Process:<br/>
            To request a refund, please contact our customer support team within 72 hours of your ticket purchase. You may do so by emailing us at [insert email address here]. Please include your full name, ticket number, and reason for the refund request in your email.
            If your refund request is approved, we will process your refund within 14 business days. Please note that refunds will only be issued to the original payment method used to purchase the raffle ticket.<br/><br/>
            <br/><br/><br/>
            Ticket Validity:<br/>
            Each raffle ticket purchased is only valid for the specific stage in which it was purchased. For example, if you purchased tickets numbered 1 to 1,000, you will only be eligible to win the prizes awarded in that range during the corresponding raffle stage.<br/><br/>
            No Refunds After Prizes Have Been Awarded:<br/>
            Once the prizes for a raffle stage have been awarded, no refunds will be granted. This includes situations where a customer purchased a ticket within the range of winning tickets, but did not claim their prize within the allotted time.
            We appreciate your business and hope that you enjoy participating in our raffle. If you have any questions or concerns regarding our refund policy, please do not hesitate to contact us.<br/><br/>
            <br/><br/>
            </p>
          </div>
        </section>

    </div>
  );
};

export default Refund;
